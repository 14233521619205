import React from "react"

import Layout from "../components/Layout"
import Section from "../components/Section"
import SEO from "../components/SEO"
import Triangle from "../components/Triangle"

import { backgroundDark, primaryDark, secondary } from "../styles/colors.module.scss"

const Background = () => (
  <div>
    <Triangle
      color={backgroundDark}
      height={["35vh", "80vh"]}
      width={["95vw", "60vw"]}
    />

    <Triangle
      color={secondary}
      height={["38vh", "80vh"]}
      width={["50vw", "35vw"]}
    />

    <Triangle
      color={primaryDark}
      height={["25vh", "35vh"]}
      width={["75vw", "60vw"]}
      invertX
    />

    <Triangle
      color={backgroundDark}
      height={["20vh", "20vh"]}
      width={["100vw", "100vw"]}
      invertX
      invertY
    />
  </div>
)

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Section Background={Background}>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Section>
  </Layout>
)

export default NotFoundPage
